import React from "react";
import AutocompleteConfig, { ImageBaseUrl } from "src/components/autocomplete/autocomplete_config";
import { AutocompleteSource, getAlgoliaResults } from "@algolia/autocomplete-js";
import { SearchClient } from "algoliasearch/lite";
import { BaseItem } from "@algolia/autocomplete-core";
import { AutocompleteResultComponent } from "src/components/autocomplete_renderer";
import { Availability, FulfillmentMethod } from "src/storefront/types/catalog";
import { Temporal } from "@js-temporal/polyfill";

export interface ProductVariant extends BaseItem {
  key: string;
  keyPath: string;
  title: string;
  fulfillmentMethods: FulfillmentMethod[];
  availabilities: { start_time: string; end_time: string; wday: number }[];
}

interface ProcessedProductVariant extends BaseItem {
  key: string;
  keyPath: string;
  title: string;
  fulfillmentMethods: FulfillmentMethod[];
  availabilities: Availability[];
}

export interface ProductResult extends BaseItem {
  objectID: string;
  fulfillmentMethods: FulfillmentMethod[];
  products: ProductVariant[];
  title: string;
}

export default class ProductAutocompleteConfig extends AutocompleteConfig<ProductResult> {
  readonly storeId: number;
  readonly storePermalink: string;
  readonly selectedFulfillmentMethod: FulfillmentMethod;

  constructor(indexName: string, imageBaseUrl: ImageBaseUrl, storeId: number, storePermalink: string, selectedFulfillmentMethod: FulfillmentMethod) {
    super(indexName, imageBaseUrl);

    this.storeId = storeId;
    this.storePermalink = storePermalink;
    this.selectedFulfillmentMethod = selectedFulfillmentMethod;
  }

  emptyQueryCollectionId = "product_suggestions"
  emptyQueryCollectionTitle = "product_search_results";
  queryCollectionId = "products";

  // TODO: Product suggestions
  emptyQueryResults(): AutocompleteSource<ProductResult> {
    return {
      sourceId: this.emptyQueryCollectionId,
      getItems: () => [],
      templates: {
        item() {
          return '<div></div>'
        }
      }
    }
  }

  filterString(): string {
    return `storeID:${this.storeId} AND fulfillmentMethods:${this.selectedFulfillmentMethod}`;
  }

  itemComponent(item: ProductResult): React.JSX.Element {
    const productVariant = this.selectMostRelevantVariant(item);
    const encodedKeyPath = productVariant.keyPath.split(";").map((segment) => encodeURIComponent(segment)).join(";");

    // Build search href that works for mobile (with /search) and desktop (w/o /search)
    const url = new URL(window.location.toString());
    const newHref = new URL(url.origin + url.pathname);
    const searchParamValue = url.searchParams.get("search_bar");

    if (searchParamValue) {
      newHref.searchParams.set("search_bar", searchParamValue);
    }
    newHref.hash = encodedKeyPath;

    return <AutocompleteResultComponent key={item.objectID} name={productVariant.title} href={newHref.toString()} />;
  }

  queryResults(searchClient: SearchClient): AutocompleteSource<ProductResult> | boolean | undefined {
    return {
      sourceId: this.queryCollectionId,
      getItems: ({ query }: { query: string }) => {
        if (query.length == 0) return [];
        return getAlgoliaResults<ProductResult>({
          searchClient,
          queries: [
            {
              indexName: this.indexName,
              query,
              params: {
                hitsPerPage: 5,
                filters: this.filterString(),
              },
            },
          ],
        });
      },
      templates: {
        item() {
          return '<div></div>'
        }
      }
    };
  }

  // TODO: "See all results" full page search for products?
  searchResultsPath() {
    return undefined;
  }

  private selectMostRelevantVariant(result: ProductResult): ProductVariant {
    const variants = result.products;

    if (variants.length == 1) return variants[0];

    const now = Temporal.Now.plainDateTimeISO();
    const wday = now.dayOfWeek;
    const time = now.toPlainTime();

    // Temporal dayOfWeek goes from 1-7 (Sunday is 7)
    // Availability wday is 0-6 (Sunday is 0)
    const fulfillableVariantsForToday = variants.filter((product) => {
      return product.fulfillmentMethods.includes(this.selectedFulfillmentMethod);
    }).map((product) => {
      return {
        ...product,
        availabilities: product.availabilities.filter((availability) => availability.wday == (wday % 7)),
      }
    });

    return fulfillableVariantsForToday.sort(this.compareVariants(time))[0];
  }

  // A lower score means more likely to be displayed
  // The current preference on what variant to show is
  // 1. Show one that is currently fulfillable
  // 2. Show the one that is next orderable today
  // 3. Otherwise just show any variant
  variantScore(time: Temporal.PlainTime, variant: ProcessedProductVariant): number {
    if (variant.availabilities.length === 0) {
      return Number.POSITIVE_INFINITY; // If the variant doesn't have any availabilities for today, then it's not orderable
    } else if (variant.availabilities.some((a) => a.covers(time))) {
      // If variant is currently fulfillable, we want to show this one so give it lowest possible value
      // We're ignoring the possibility of a product being currently available across two variants and needing to pick the 'better' one to show here
      return Number.NEGATIVE_INFINITY;
    } else if (variant.availabilities.every((a) => { return a.entirelyBefore(time) })) {
      // If every product variant is no longer fulfillable, then let's not care about which one we show
      return Number.POSITIVE_INFINITY;
    } else {
      // Return the amount of time until the next fulfillable availability
      const nextUpcomingAvailabilities = variant.availabilities
        .filter((a) => { return time.until(a.startTime).sign >= 0 }) // Only availabilities that are upcoming
        .sort((availability1, availability2) => {
          return time.until(availability1.startTime).total("minutes") - time.until(availability2.startTime).total("minutes")
        });

      return time.until(nextUpcomingAvailabilities[0].startTime).total("minutes");
    }
  }

  compareVariants(now: Temporal.PlainTime) {
    return (variant1: ProductVariant, variant2: ProductVariant) => {
      const processedVariant1 = {
        ...variant1,
        availabilities: variant1.availabilities.map((availability) => new Availability(Temporal.PlainTime.from(availability.start_time), Temporal.PlainTime.from(availability.end_time), availability.wday)),
      }
      const processedVariant2 = {
        ...variant2,
        availabilities: variant2.availabilities.map((availability) => new Availability(Temporal.PlainTime.from(availability.start_time), Temporal.PlainTime.from(availability.end_time), availability.wday)),
      }

      return this.variantScore(now, processedVariant1) - this.variantScore(now, processedVariant2);
    }
  }
}

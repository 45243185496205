import React, { useEffect, useMemo } from "react";
import { AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import { ReactComponent as SearchAlternate } from "../../images/icons/search-alternate.svg";
import { ReactComponent as ImageSquareFallback } from "../../images/image-square-fallback.svg";

import AutocompleteConfig from "src/components/autocomplete/autocomplete_config";

import debounce from "src/debounce";

export default function AutocompleteRenderer<T extends BaseItem>({
  autocompleteState,
  autocompleteConfig,
}: {
  autocompleteState: AutocompleteState<T>;
  autocompleteConfig: AutocompleteConfig<T>;
}) {
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const resultsRef = React.useRef<HTMLInputElement>(null)

  const [debounceLoading, cancelDebouncedLoading] = useMemo(() => debounce(setShowLoading, 200), []);

  const collectionFromId = React.useCallback((sourceId: string) => autocompleteState.collections.find(collection => collection.source.sourceId === sourceId), [autocompleteState.collections]);
  const emptyQueryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.emptyQueryCollectionId), [collectionFromId, autocompleteConfig.emptyQueryCollectionId]);
  const queryCollection = React.useMemo(() => collectionFromId(autocompleteConfig.queryCollectionId), [collectionFromId, autocompleteConfig.queryCollectionId]);

  useEffect(() => {
    if (autocompleteState.status == "loading") {
      debounceLoading(true);
    }
    if (autocompleteState.status == "idle") {
      cancelDebouncedLoading();
      setShowLoading(false);
    }
  }, [autocompleteState.status, debounceLoading, cancelDebouncedLoading]);

  const renderEmptyQueryResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items" ref={resultsRef}>
        <div className="space-pl-md space-pr-md space-pb-xs">
          <span className="label-text description">{autocompleteConfig.emptyQueryCollectionTitle}</span>
        </div>
        {emptyQueryCollection?.items.map(item => autocompleteConfig.itemComponent(item))}
      </div>
    );
  }, [emptyQueryCollection?.items, autocompleteConfig])

  const renderSearchResults = React.useMemo(() => {
    return (
      <div className="list has-hoverable-items">
        {queryCollection?.items.map(item => autocompleteConfig.itemComponent(item))}

        {autocompleteConfig.searchResultsPath(autocompleteState.query) ?
          <a className="list-item" href={autocompleteConfig.searchResultsPath(autocompleteState.query)} >
            <div className="is-flexbox space-p-xs">
              <SearchAlternate className="icon streamline-icon is-medium" />
            </div>

            <span className="label-text">All results for <b>{autocompleteState.query}</b></span>
          </a> : <></>
        }

      </div>
    );
  }, [autocompleteState.query, queryCollection?.items, autocompleteConfig]);

  const noResults = React.useMemo(() => {
    return (
      <div className="list">
        <span className="list-item label-text">No results</span>
      </div>
    )
  }, [])

  const renderResultsLoadingPlaceholder = (
    <div className="container">
      <div className="skeleton is-heading"></div>
      <div className="skeleton is-paragraph"></div>
      <div></div>
    </div>
  )

  const autocompleteResults = React.useCallback(() => {
    if (emptyQueryCollection && emptyQueryCollection.items.length == 0) {
      return <></>
    } else if (emptyQueryCollection && emptyQueryCollection.items.length > 0) {
      return renderEmptyQueryResults;
    } else if (queryCollection?.items?.length) {
      return renderSearchResults;
    } else {
      return noResults;
    }
  }, [noResults, emptyQueryCollection, renderEmptyQueryResults, renderSearchResults, queryCollection]);

  return showLoading ? renderResultsLoadingPlaceholder : autocompleteResults();
}

export function AutocompleteResultComponent({ name, href, imageUrl }: { name: string; href: string; imageUrl?: string }) {
  return <a href={href} className="list-item">
    {
      imageUrl ? <div
        className="thumbnail-image is-circle flex-shrink-0"
        data-lazy-images-target="image"
        data-lazy-images-imagery-path-value={imageUrl}
      /> : <ImageSquareFallback className="thumbnail-image is-circle icon streamline-icon is-medium" />
    }
    <span className="label-text description">{name}</span>
  </a>
}

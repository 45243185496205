import { AutocompleteSource } from "@algolia/autocomplete-js";
import { BaseItem } from "@algolia/autocomplete-core";
import { SearchClient } from "algoliasearch/lite";
import React from "react";

export type ImageBaseUrl = {
  protocol: string;
  domain: string;
  port?: number;
}

export default abstract class AutocompleteConfig<T extends BaseItem> {
  indexName: string;
  imageBaseUrl: ImageBaseUrl;

  protected constructor(indexName: string, imageBaseUrl: ImageBaseUrl) {
    this.indexName = indexName;
    this.imageBaseUrl = imageBaseUrl;
  }

  abstract readonly emptyQueryCollectionId: string;
  abstract readonly emptyQueryCollectionTitle: string;
  abstract readonly queryCollectionId: string;

  abstract filterString(): string;

  abstract emptyQueryResults(): AutocompleteSource<T> | boolean | undefined;

  abstract queryResults(searchClient: SearchClient): AutocompleteSource<T> | boolean | undefined;

  abstract searchResultsPath(query: string): string | undefined;

  abstract itemComponent(item: T): React.JSX.Element;
}
